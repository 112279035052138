export const gridData = [{
    "ProductID": 1,
    "ProductName": "Senador Parrilli",
    "SupplierID": 1,
    "CategoryID": 1,
    "UnitPrice": 20
},
{
    "ProductID": 1,
    "ProductName": "Senadora Sapag",
    "SupplierID": 1,
    "CategoryID": 1,
    "UnitPrice": 21
},
{
    "ProductID": 9,
    "ProductName": "Senadora CREXELL",
    "SupplierID": 4,
    "CategoryID": 6,
    "UnitPrice": 15
},
{
    "ProductID": 10,
    "ProductName": "Senadora Doñate",
    "SupplierID": 4,
    "CategoryID": 8,
    "UnitPrice": 19
},
{
    "ProductID": 10,
    "ProductName": "Senadora Larraburu",
    "SupplierID": 4,
    "CategoryID": 8,
    "UnitPrice": 24
},
{
    "ProductID": 10,
    "ProductName": "Senadora Silva",
    "SupplierID": 4,
    "CategoryID": 8,
    "UnitPrice": 14
},
{
    "ProductID": 10,
    "ProductName": "Senadora Wado",
    "SupplierID": 4,
    "CategoryID": 8,
    "UnitPrice": 9
},
{
    "ProductID": 10,
    "ProductName": "Senadora Lousteau",
    "SupplierID": 4,
    "CategoryID": 8,
    "UnitPrice": 15
}
];

export const donutChartData = [{
    "kind": "Senador CORNEJO",
    "share": 0.01
}, {
    "kind": "Senador DI TULLIO",
    "share": 0.01
}, {
    "kind": "Senador BLANCO",
    "share": 0.01
}, {
    "kind": "Senadora JURI",
    "share": 0.01
}, {
    "kind": "Senadora MENDOZA",
    "share": 0.01
}, {
    "kind": "Senadora ANDRADA",
    "share": 0.02
},
{
    "kind": 'Senadora FIAD',
    "share": 0.02
},
{
    "kind": 'Senadora YEDLIN',
    "share": 0.02
},
{
    "kind": 'Senadora HUALA',
    "share": 0.01
},
{
    "kind": 'Senadora LOUSTEAU',
    "share": 0.01
},
{
    "kind": 'Senadora VEGA',
    "share": 0.01
},
{
    "kind": 'Senadora LINARES',
    "share": 0.01
},
{
    "kind": 'Senadora DURÉ',
    "share": 0.01
},
{
    "kind": 'Senadora GONZÁLEZ RIOLLO',
    "share": 0.01
}, {
    "kind": 'Senadora CORPACCI',
    "share": 0.01
}, {
    "kind": 'Senadora GONZÁLEZ',
    "share": 0.01
}, {
    "kind": 'Senadora GIACOPPO',
    "share": 0.03
}, {
    "kind": 'Senadora SAPAG',
    "share": 0.01
}, {
    "kind": 'Senador GARCÍA',
    "share": 0.01
}, {
    "kind": 'Senador SCHIAVONI',
    "share": 0.03
}, {
    "kind": 'Senadora CREXELL',
    "share": 0.03
}
]

export const barChartData = [{
    name: 'Senador CORNEJO',
    data: [1, 0]
},
{
    name: 'Senador DI TULLIO',
    data: [1, 0]
}, {
    name: 'Senador BLANCO',
    data: [1, 0]
},
{
    name: 'Senadora JURI',
    data: [1, 0]
},
{
    name: 'Senadora MENDOZA',
    data: [1, 0]
},
{
    name: 'Senadora ANDRADA',
    data: [2, 0]
},
{
    name: 'Senadora FIAD',
    data: [2, 0]
},
{
    name: 'Senadora YEDLIN',
    data: [2, 0]
},
{
    name: 'Senadora HUALA',
    data: [1, 0]
},
{
    name: 'Senadora LOUSTEAU',
    data: [1, 0]
},
{
    name: 'Senadora VEGA',
    data: [1, 0]
},
{
    name: 'Senadora LINARES',
    data: [1, 0]
},
{
    name: 'Senadora DURÉ',
    data: [1, 0]
},
{
    name: 'Senadora GONZÁLEZ RIOLLO',
    data: [1, 0]
}, {
    name: 'Senadora CORPACCI',
    data: [1, 0]
}, {
    name: 'Senadora GONZÁLEZ',
    data: [1, 0]
}, {
    name: 'Senadora GIACOPPO',
    data: [3, 0]
}, {
    name: 'Senadora SAPAG',
    data: [1, 0]
}, {
    name: 'Senador GARCÍA',
    data: [1, 0]
}, {
    name: 'Senador SCHIAVONI',
    data: [3, 0]
}, {
    name: 'Senadora CREXELL',
    data: [3, 1]
}
]

export const barChartCategories = [2023, 2024];