import React from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { classNames } from "@progress/kendo-react-common";
class CustomPanelBarItem extends React.Component {
  imageUrl = (imageName) => {
    return window.baseUrl + "/images/k3-sample-app/" + imageName + ".png";
  };

  handleItemSelect = () => {
    this.props.onClick.call(undefined, { target: this });
  };
  render() {
    const className = classNames("teamMate", {
      "k-state-selected": this.props.selected === this.props.uniquePrivateKey,
    });

    return (
      <div
        className={className}
        id={this.props.id}
        onClick={this.handleItemSelect}
      >
        <span className="mate-info">
          <h2>{this.props.title}</h2>
          <p>{this.props.subTitle}</p>
        </span>
      </div>
    );
  }
}
export default class PanelBarContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: ".0.0",
    };
  }
  handleItemSelect = (event) => {
    if (event.target.props.uniquePrivateKey.length > 2) {
      this.setState({ selected: event.target.props.uniquePrivateKey });
      this.props.changeData(event.target.props.title);
    }
  };

  render() {
    return (
      <PanelBar
        expandMode="single"
        onSelect={this.handleItemSelect}
        selected={this.state.selected}
      >
        <PanelBarItem expanded={true} title="Senadores">
          <CustomPanelBarItem
            onClick={this.handleItemSelect}
            selected={this.state.selected}
            title="Senadora CREXELL"
            subTitle="Senador por Neuquén"
            smallName=""
            id="andrewFuller"
            uniquePrivateKey=".0.0"
          />
          <CustomPanelBarItem
            onClick={this.handleItemSelect}
            selected={this.state.selected}
            title="Senador YEDLIN"
            subTitle="Senador por Tucumán"
            smallName=""
            id="nancyLeverling"
            uniquePrivateKey=".0.1"
          />
          <CustomPanelBarItem
            onClick={this.handleItemSelect}
            selected={this.state.selected}
            title="Senador FIAD"
            subTitle="Senador por Jujuy"
            smallName=""
            id="robertKing"
            uniquePrivateKey=".0.2"
          />
          <CustomPanelBarItem
            onClick={this.handleItemSelect}
            selected={this.state.selected}
            title="Senadora GIACOPPO"
            subTitle="Senador por Jujuy"
            smallName=""
            id="laurenceLebihan"
            uniquePrivateKey=".0.3"
          />
          <CustomPanelBarItem
            onClick={this.handleItemSelect}
            selected={this.state.selected}
            title="Senador SCHIAVONI"
            subTitle="Senador de Misiones"
            smallName=""
            sid="marySaveley"
            uniquePrivateKey=".0.4"
          />
          <CustomPanelBarItem
            onClick={this.handleItemSelect}
            selected={this.state.selected}
            title="Senador ANDRADA"
            subTitle="Senador por Catamarca"
            smallName=""
            id="hariKumar"
            uniquePrivateKey=".0.5"
          />
        </PanelBarItem>
      </PanelBar>
    );
  }
}
