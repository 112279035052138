import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Sparkline } from "@progress/kendo-react-charts";
import { process } from "@progress/kendo-data-query";

class SparkLineChartCell extends React.Component {
  render() {
    return (
      <td>
        <Sparkline data={this.props.dataItem.PriceHistory} />
      </td>
    );
  }
}

export default class GridContainer extends React.Component {
  state = {
    dataState: {
      sort: [
        {
          field: "UnitPrice",
          dir: "asc",
        },
      ],
    },
  };
  processData = (data) => {
    data.forEach((item) => {
      item.PriceHistory = Array.from({ length: 40 }, () =>
        Math.floor(Math.random() * 100)
      );
      return item;
    });
    return process(data, this.state.dataState);
  };

  handleDataStateChange = (e) => {
    this.setState({
      dataState: e.data,
    });
  };

  render() {
    return (
      <div>
        <Grid
          style={{ height: "300px" }}
          data={this.processData(this.props.data)}
          {...this.state.dataState}
          onDataStateChange={this.handleDataStateChange}
          sortable
        >
          <Column field="ProductName" title="Nombre y Apellido" />
          <Column
            field="UnitPrice"
            title="Cantidad de asesores Permanente/Transitoria"
            width="450px"
          />
        </Grid>
      </div>
    );
  }
}
